<template>
  <div class="page-job-container">
    <div class="page-title js-fixed-global">Join Us</div>
    <div class="page-title-4-mobile" v-if="$isMobile">Join Us</div>
    <div class="job-main-body">
      <div class="job-lists-container">
        <div class="job-item scroll-up-to-show"
          v-for="(job,index) in lists"
          :key="index"
        >
          <div class="title">{{job.name.en}} {{job.name.cn}}</div>
          <div class="content" v-html="job.content"></div>
          <div class="location">{{job.location.en}} | {{job.location.cn}}</div>
        </div>
      </div>
      <div class="apply-way-container">
        <div class="title  scroll-up-to-show text">To apply 應聘渠道</div>
        <div class="info-body">
          <div class="info-item  scroll-up-to-show text">
            <div class="location">Shanghai | 上海</div>
            <div class="contacts-info">
              <p>Tel_(086)-021-61313410</p>
              <p>E-mail_dia@diash.cn</p>
            </div>
          </div>
          <div class="info-item  scroll-up-to-show text">
            <div class="location">Shenzhen | 深圳</div>
            <div class="contacts-info">
              <p>Tel_(086)-0755-82565580</p>
              <p>E-mail_dia@diasz.cn</p>
            </div>
          </div>
        </div>
      </div>
      <div class="page-job-footer-wrapper">
          <Footer></Footer>
      </div>
    </div>
  </div>
</template>

<script>
import Footer from '@/components/footer.vue';

export default {
  components: {
    Footer,
  },
  beforeCreate() {
    // this.$store.commit('openLoading');
  },
  created() {
    // setTimeout(() => {
    //   this.$store.commit('closeLoading');
    // }, 2000);
  },
  data() {
    return {
      lists: null,
    };
  },
  watch: {
    $route: {
      handler: 'init',
      immediate: true,
    },
  },
  methods: {
    async init() {
      const that = this;
      const t1 = new Date().getTime();
      that.$store.commit('openLoading');
      const res = await that.$http.get('/api/job/list');
      const { lists } = res.data;
      if (lists.length > 0) {
        that.lists = lists.map((item) => {
          const temp = item;
          temp.name = JSON.parse(temp.name);
          temp.location = JSON.parse(temp.location);
          return temp;
        });
      }
      const t2 = new Date().getTime();
      if (t2 - t1 <= 500) {
        setTimeout(() => {
          that.$store.commit('closeLoading');
        }, 1000);
      } else {
        that.$store.commit('closeLoading');
      }
    },
  },
};
</script>

<style></style>
