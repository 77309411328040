<template>
  <div class="page-mobile-project-list">
    <div
      class="fixed-top-types js-fixed-global"
      v-if="getTypes"
      id="fixed-top-types"
      @click.stop.prevent="switchHead"
    >
      <div
        class="wrapper"
        ref="wrapper"
        :style="{
          height: `${headHeight}px`
        }"
      >
        <div tag="div" class="type__child">
          <p>
            <span>{{ nowTypes ? `${nowTypes.name.en}` : "" }}</span
            ><span style="margin-top:-2px;margin-left:5px;margin-right:5px;">|</span
            ><span>{{ nowTypes ? `${nowTypes.name.cn}` : "" }}</span>
          </p>
        </div>
        <div
          v-for="(child, index) in getTypes"
          :key="index"
          class="type__child"
          @click.stop="jump(`/project-list/${child.id}`)"
        >
          <template v-if="Number(child.id) !== Number(type)">
            <p>{{ child.name.en }}</p>
          </template>
        </div>
      </div>
    </div>
    <div class="home-project-list-container" id="home-project-list-container">
      <ul class="page-mobile-project-types" v-if="getTypes">
        <li
          v-for="(child, index) in getTypes"
          :key="index"
          :class="[Number(child.id) === Number(type) ? 'actived' : '']"
        >
          <router-link
            :to="{
              path: `/project-list/${child.id}`
            }"
            class="link"
          >
            <span>{{ child.name.en }}</span>
            <span v-if="Number(child.id) === Number(type)" style="margin-top:-2px;margin-left:5px;margin-right:5px;">|</span>
            <span> {{ Number(child.id) === Number(type) ? child.name.cn : "" }}</span></router-link
          >
        </li>
      </ul>
      <div class="home-project-wrapper" ref="home-project-wrapper" id="home-project-wrapper">
        <div class="wrapperccc">
          <div class="home-project-wrapper__main-container" v-if="cases.length > 0">
            <router-link
              class="project-group scroll-up-to-show"
              :id="`case-${item.id}`"
              v-for="(item, index) in cases"
              :key="index"
              tag="div"
              :to="`/project/${item.id}`"
            >
              <div class="project-group__case">
                <div class="project-group-case__item">
                  <img class="lazyload" :src="item.cover" alt="" :data-src="item.cover" />
                  <div class="text">
                    {{ item.location.en }} {{ item.title.en }} | {{ item.location.cn }}
                    {{ item.title.cn }}
                  </div>
                </div>
              </div>
            </router-link>
          </div>
          <div class="" v-else>暂无数据</div>
          <div class="home-project-wrapper__more-bt" style="display:none;">
            <div class="bt-desc-line"></div>
            <router-link tag="div" class="text" to="/project-simple-list"
              >more projects</router-link
            >
          </div>
          <div class="page-more-bt-container" v-if="hasMore > 0">
            <router-link
              tag="div"
              class="page-more-bt"
              :class="[!$isMobile ? 'hover' : '']"
              :to="{
                path: `/project-simple-list/${type}`
              }"
              ><p>more projects</p></router-link
            >
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { preloadDom, scrollTo } from '@/assets/utils/common';
import Footer from '@/components/footer.vue';
import { mapMutations, mapState } from 'vuex';

export default {
  components: {
    Footer,
  },
  props: {
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      cases: undefined,
      hasMore: false,
      headOpen: false,
      headHeight: 0,
      oHeight: 0,
      loading: undefined,
    };
  },
  computed: {
    ...mapState(['backTargetID', 'scrollBarInit']),
    targetID() {
      return this.$route.query.targetID || 0;
    },
    getTypes() {
      const list = this.$store.state.typesList;
      if (list && list.length > 0) {
        this.$nextTick(() => {
          const obj = document.querySelector('.type__child');
          this.headHeight = obj.getBoundingClientRect().height;
          // this.headHeight = this.oHeight * list.length;
        });
      }
      return list;
    },
    nowTypes() {
      const xxx = this.getTypes
        ? this.getTypes.find((item) => Number(item.id) === Number(this.type))
        : undefined;
      return xxx;
    },
  },
  watch: {
    scrollBarInit: {
      handler(val) {
        if (val) {
          this.jumpToTarget();
        }
      },
      immediate: true,
    },
    type: {
      handler(val) {
        if (this.getTypes) {
          const currentType = this.getTypes.filter((item) => Number(item.id) === Number(val));
          this.hasMore = Number(currentType['0'].hasSimple);
        }
      },
      immediate: true,
    },
    $route: {
      handler: 'init',
      immediate: true,
    },
    getTypes: {
      handler(val) {
        if (Number(this.type) === 0) {
          this.type = val['0'].id;
        }
        if (val) {
          const currentType = val.filter((item) => Number(item.id) === Number(this.type));
          this.hasMore = Number(currentType['0'].hasSimple);
        }
      },
      immediate: true,
    },
    headOpen(val) {
      const that = this;
      this.$nextTick(() => {
        if (that.$refs.wrapper) {
          if (val) {
            this.headHeight *= this.getTypes.length;
          } else {
            this.headHeight /= this.getTypes.length;
          }
        }
      });
    },
  },
  methods: {
    ...mapMutations(['clearTargetID']),
    async init() {
      this.headOpen = false;
      await this.getCases();
    },
    jumpToTarget() {
      const id = this.backTargetID;
      if (id > 0) {
        this.$nextTick(() => {
          const obj = document.getElementById(`case-${id}`);
          if (obj) {
            const top = obj.getBoundingClientRect().top - 60;
            scrollTo(top);
            this.clearTargetID();
          }
        });
      }
    },
    async getCases() {
      const that = this;
      that.loading = true;
      that.cases = [];
      that.$store.commit('openLoading');
      const t1 = new Date().getTime();
      try {
        const res = await that.$http.get('/api/product/list', {
          page: 0,
          type: that.type,
          is_on_home: 1,
        });
        const { lists, meta } = res.data;
        const imgs = [];
        lists.map((item) => {
          const temp = item;
          temp.title = JSON.parse(item.name);
          temp.location = JSON.parse(item.location);
          imgs.push(temp.cover);
          return temp;
        });
        that.cases = lists;
        that.pageCount = meta.pageCount;
        that.page += 1;
      } catch (e) {
        Error('图片错误');
      }
      await this.$nextTick();
      await preloadDom(document.querySelectorAll('.lazyload'));
      that.loading = false;
      const t2 = new Date().getTime();
      if (t2 - t1 <= 500) {
        setTimeout(() => {
          that.$store.commit('closeLoading');
        }, 1000);
      } else {
        that.$store.commit('closeLoading');
      }
    },
    switchType(type) {
      this.$router.push({
        path: '/index.html',
        query: {
          type,
        },
      });
    },
    switchHead() {
      this.headOpen = !this.headOpen;
    },
    jump(url) {
      this.$router.push({
        path: url,
      });
    },
  },
};
</script>

<style></style>
