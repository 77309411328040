import Scrollbar from 'smooth-scrollbar';
import { TweenMax, Power1 } from 'gsap';
import $ from 'jquery';
import Masonry from 'masonry-layout';
import store from '@/store';
import router from '@/router';
import bowser from 'bowser';
import Parallax from './parallax';

let scrollBar;
const $body = $('body');
const $window = $(window);

function getQueryVariable(variable) {
  const query = window.location.search.substring(1);
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i += 1) {
    const pair = vars[i].split('=');
    if (pair[0] === variable) { return pair[1]; }
  }
  return (false);
}

// 两边的东西
class CoverStripe {
  constructor(t) {
    this.$el = t;
    this.$shrinkElement = this.$el.find('.cover-stripe');
    this.container = '';
    if (this.$shrinkElement.length > 0) {
      this.init();
    }
  }
}

CoverStripe.prototype.init = function init() {
  this.initTween();
};

CoverStripe.prototype.initTween = function initTween() {
  TweenMax.set(this.$shrinkElement, {
    scaleX: 0,
  });
  this.tween = TweenMax.fromTo(this.$shrinkElement, 10, {
    scaleX: 0,
  }, {
    scaleX: 1,
    ease: Power1.easeInOut,
  });
  this.tween.pause();
};

CoverStripe.prototype.computeData = function () {
  this.elBCR = this.$el[0].getBoundingClientRect();
  this.boundaries = {
  };
  this.boundaries.top = scrollBar.offset.y + this.elBCR.top;
  this.boundaries.bottom = this.boundaries.top + this.elBCR.height;
  this.boundaries.height = this.elBCR.height;
};

CoverStripe.prototype.update = function (e) {
  if (this.$shrinkElement.length > 0) {
    const xxx = e || scrollBar.offset.y;
    this.computeData();
    this.offsetScroll = xxx + window.innerHeight / 2 - this.boundaries.top;
    this.percentage = this.offsetScroll / this.boundaries.height;
    if (this.percentage <= 0) {
      this.percentage = 0;
    } else if (this.percentage > 1) {
      this.percentage = 1;
    }
    this.tween.progress(this.percentage).play().pause();
  }
};

function showIndexMenu() {
  const $homeProjectWrapper = $('.home-project-wrapper');
  if ($homeProjectWrapper.length > 0) {
    const offset = $homeProjectWrapper.offset().top;
    const space = Number(400 / 1920) * $(window).width();
    const isShow = $homeProjectWrapper.hasClass('show');
    if (offset <= space) {
      if (!isShow) {
        $homeProjectWrapper.addClass('show');
        console.log('show');
      }
    } else if (isShow) {
      $homeProjectWrapper.removeClass('show');
      console.log('hidden');
    }
  }
}

function getImgWidth(width) {
  // 1920
  const limit = bowser.mobile ? 750 : 1910;
  const fw = bowser.mobile ? width * 0.462 : width;
  return Math.ceil((fw / limit) * $body.width());
}

function show(obj, i) {
  setTimeout(() => {
    $(obj).addClass('animated');
  }, i * 100); // 这一行将i*1000改为j*1000也行，并不影响
}

function preloadSet(obj) {
  if (obj.length > 0) {
    const image = new Image();
    image.onload = function () {
      // 重置宽度
      obj.css({
        // 1920
        width: getImgWidth(image.width),
      });
    };
    image.onerror = function () {
      console.log('图片错误');
    };
    image.src = obj.attr('src');
  }
}

async function uptoshow() {
  const $scrollUpToShow = $('.scroll-up-to-show');
  const offset = $window.height() * 0.98;
  const shows = $scrollUpToShow.filter((k, v) => {
    const hasAnimated = $(v).hasClass('animated');
    const { top } = v.getBoundingClientRect();
    return top <= offset && top >= -1 && !hasAnimated;
  });
  if (shows.length > 0) {
    let i = 0;
    $.each(shows, (k, v) => {
      i += 1;
      // const $lazyLoad = $(v).find('.lazyload');
      // if ($lazyLoad.length > 0) {
      //   const image = new Image();
      //   image.onload = function () {
      //     // 重置宽度
      //     $lazyLoad.css({
      //       // 1920
      //       width: getImgWidth(image.width),
      //     });
      //     show(v, i);
      //   };
      //   image.onerror = function () {
      //     console.log('图片错误');
      //   };
      //   image.src = $lazyLoad.attr('src');
      // } else {

      // }
      show(v, i);
    });
  }
}

function bindScroll() {
  // 图片parallax
  const $picWrapper = $('.parallax-container');
  const $picture = $picWrapper.find('img');
  const parallax = new Parallax($picture, $picWrapper);
  parallax.init();
  const $jsScrollGolbal = $('.js-fixed-global');
  // 图片两边遮罩
  const $jsShrinkOnScroll = $('.js-shrink-on-scroll');
  const shrinnk = new CoverStripe($jsShrinkOnScroll);
  shrinnk.init();
  // about文字显示
  const $aboutText = $('#about-content__wrapper');
  // 页面总长度
  // const pageHeight = $('#scroll-wrapper .scroll-content').height();
  // 详情两边
  const $jsSideBar = $('.js-side-bar');
  $jsSideBar.removeClass('active');
  // 滚动dom
  // const scrollContent = $('.scroll-content')['0'];
  // 图片显示
  let p = 0;
  let t = 0;
  // openbt
  const $pageHeaderContainer = $('.page-menu-container');
  const FancyBCR = $('.home-scroll-container').length > 0 ? $('.home-scroll-container')['0'].getBoundingClientRect() : null;
  scrollBar.addListener((status) => {
    // 显示about文字
    p = status.offset.y;
    if (t <= p) {
      if (store.state.currentPage !== 'index') {
        $pageHeaderContainer.addClass('--hidden');
        $pageHeaderContainer.removeClass('--show');
      } else if (p >= FancyBCR.bottom - 200) {
        $pageHeaderContainer.addClass('--show');
      }
    } else {
      $pageHeaderContainer.addClass('--show');
    }
    if (store.state.currentPage === 'index') {
      if (p <= 900) {
        $pageHeaderContainer.removeClass('--show');
      }
    }
    setTimeout(() => {
      t = p;
    }, 0);
    if (status.offset.y / status.limit.y >= 0.6) {
      $aboutText.addClass('active');
      $jsSideBar.addClass('active');
    } else {
      $aboutText.removeClass('active');
      $jsSideBar.removeClass('active');
    }
    parallax.update();
    shrinnk.update();
    showIndexMenu(status);
    uptoshow();
    // 底部显示线
    const $pageFooterContainer = $('.page-footer-container');
    if (status.offset.y >= status.limit.y - 100) {
      if (!$pageFooterContainer.hasClass('show-line')) {
        $pageFooterContainer.addClass('show-line');
      }
    } else {
      $pageFooterContainer.removeClass('show-line');
    }

    [...$jsScrollGolbal].forEach((item) => {
      if (item.dataset.pause === undefined) {
        TweenMax.set(item, {
          y: status.offset.y,
        });
      } else if (!$aboutText[0].classList.contains('active')) {
        TweenMax.set(item, {
          y: status.offset.y,
        });
      }
    })

    if (bowser.mobile) {
      // 项目列表头部下来
      const nmb = document.querySelector('#home-project-wrapper');
      const headTypes = document.querySelector('#fixed-top-types');
      if (nmb) {
        const wbcr = nmb.getBoundingClientRect();
        if (!headTypes.classList.contains('show') && wbcr.top <= 20) {
          headTypes.classList.add('show');
        } else if (wbcr.top > 20) {
          headTypes.classList.remove('show');
        }
      }
      // about文字
      const atext = document.querySelector('#about_text__title');
      if (atext) {
        if (p > 10) {
          atext.classList.add('hidden');
        } else {
          atext.classList.remove('hidden');
        }
      }
    }
  });
}

function scrollTo(scrollTop, duration) {
  const dura = duration || 0;
  scrollBar.scrollTo(0, (scrollTop || 0), dura);
}

function diableScrollBar() {
  console.log('11');
}

function openNav() {
  $('body').addClass('--nav-open');
  TweenMax.fromTo('.page-menu-list-container', 0.6, {
    height: '0',
  }, {
    height: '100vh',
  });
}
function closeNav() {
  $('body').removeClass('--nav-open');
  TweenMax.fromTo('.page-menu-list-container', 0.6, {
    height: '100vh',
  }, {
    height: '0',
  });
}

function bindNav() {
  const $pageHeaderContainer = $('.js-menu-switch-container');
  const $open = $pageHeaderContainer.find('.openBt');
  const $close = $pageHeaderContainer.find('.closeBt');
  $open.on('click', openNav);
  $close.on('click', closeNav);
}

function pageLoading() {
  $body.addClass('--loading');
  store.commit('scrollBarPrepare');
  if (store.state.currentPage === 'index') {
    const $pageHeaderContainer = $('.page-menu-container');
    $pageHeaderContainer.addClass('--hidden');
    $pageHeaderContainer.removeClass('--show');
  }
}

function initScrollBar() {
  const $scrollUpToShow = $('.scroll-up-to-show');
  if ($scrollUpToShow.length > 0) {
    $scrollUpToShow.removeClass('animated');
  }
  Scrollbar.destroyAll();
  $body.removeClass('--loading');
  const obj = $('#scroll-wrapper')['0'];
  const config = {
    speed: 0.5,
    damping: 0.1,
    thumbMinSize: 20,
    renderByPixels: !0,
    syncCallbacks: !1,
    alwaysShowTracks: !1,
    continuousScrolling: false,
    overscrollEffect: !1,
    overscrollEffectColor: '#87ceeb',
    overscrollDamping: 0.2,
    wheelEventTarget: obj,
  };
  scrollBar = Scrollbar.init(obj, config);
  store.commit('scrollBarHasInited');

  const queryName = getQueryVariable('name');
  const { type } = router.app.$route.query;
  console.log(queryName);
  const hc = $('#home-project-list-container');
  if (hc.length === 1) {
    // const jumpBCR = hc.getBoundingClientRect();
    // const offset = Number(jumpBCR.top) + 400;
    // scrollBar.scrollTo(0, jumpBCR.top + 300, 10);
    // uptoshow();
    const offsetTop = -(parseInt(hc.css('padding-top'), 10) / 2);
    if (type !== undefined) {
      scrollBar.scrollIntoView(hc['0'], {
        alignToTop: true,
        offsetTop,
      });
    }
  }

  uptoshow();

  // 初始化其他
  const $jsScrollGolbal = $('.js-fixed-global');
  TweenMax.set($jsScrollGolbal, {
    clearProps: 'all',
  });
  const $homeProjectWrapper = $('.home-project-wrapper');
  if ($homeProjectWrapper.length > 0) {
    $homeProjectWrapper.removeClass('show');
  }
  // 导航展开
  $('.page-menu-list-container').css('height', 0);
  // 绑定scroll
  bindScroll();
  // 绑定展开关闭导航
  bindNav();
}

function initParallax() {
  const imgs = $('.js-parallax');
  console.log(imgs);
}

function preloadDom(domlists) {
  const doms = Array.from(domlists);
  let promises;
  if (doms.length > 0) {
    promises = doms.map((item) => new Promise((res, rej) => {
      const img = new Image();
      const { src } = item.dataset;
      img.onload = function () {
        $(item).css({
          width: getImgWidth(img.width),
        });
        res();
      };
      img.onerror = function (e) {
        rej(e);
      };
      img.src = src;
    }));
  } else {
    promises = [Promise.resolve()];
  }
  return Promise.all(promises);
}

function preloadImgs(imglists, delay) {
  const imgs = imglists || [];
  const timeDelay = delay || 100;
  return Promise.all(
    imgs.map((item) => new Promise((res, rej) => {
      const img = new Image();
      img.onload = (e) => {
        // if (size < 10) {

        // } else {
        //   res(e);
        // }
        setTimeout(() => {
          res(e);
        }, 1 * 1000);
      };
      img.onerror = (e) => {
        setTimeout(() => {
          img.src = item;
          rej(e);
        }, timeDelay);
      };
      img.src = item;
    })),
  );
}

function waterfallLayout(wrapper, items) {
  const $wrapper = document.querySelector(`.${wrapper}`);
  const width = $(window).width();
  const space = bowser.mobile ? 0 : Number(30 / 1920) * width;
  const msnry = new Masonry($wrapper, {
    itemSelector: `.${items}`,
    isAnimated: true,
    gutter: space,
  });
  msnry.once('layoutComplete', () => {
    $wrapper.classList.add('load');
  });
  msnry.layout();
  // 显示
  uptoshow();
}
function test() {
  console.log('1111');
}

function sup() {
  scrollBar.update();
}
export {
  initScrollBar, initParallax, preloadImgs, scrollTo, waterfallLayout, pageLoading, test, preloadDom, preloadSet, diableScrollBar, sup,
};
