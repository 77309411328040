<template>
  <div
    class="page-common-wrapper-body scroll-wrapper"
    id="scroll-wrapper"
    :style="{
      height: `${height}`
    }"
  >
    <div
      class="header"
      :class="[$isMobile && $route.meta.headerFixed ? 'fixed js-fixed-global' : '']"
    >
      <slot name="header"></slot>
    </div>
    <div class="page-common-main-container">
      <slot name="main"></slot>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      height: this.$isMobile ? `${window.innerHeight}px` : '100vh',
    };
  },
};
</script>

<style></style>
