<template>
  <div class="page-project-detail-container" v-if="info">
    <div class="project-title-container">
      <div class="project-title-container__title">
        <p>{{ info.name.cn }}</p>
        <p>{{ info.name.en }}</p>
      </div>
      <div class="project-location">{{ info.location.en }} | {{ info.location.cn }}</div>
    </div>
    <div class="project-detail-cover-container" v-if="!$isMobile">
      <div class="project-detail-cover-container__pic_box parallax-container">
        <div class="cover-stripe cover-stripe--left"></div>
        <div class="cover-stripe cover-stripe--right"></div>
        <img :src="info.inner_big" alt="" />
      </div>
      <div class="site-header-fixed-container js-fixed-global js-menu-switch-container">
        <router-link tag="div" class="logo" to="/"></router-link>
      </div>
    </div>
    <div class="project-detail-content-container">
      <div class="project-detail-content–container__main-body">
        <div class="__content">
          <div
            v-for="(item, index) in info.content"
            :key="index"
            class="scroll-up-to-show"
            :class="[
              item.type === 'img' ? 'img' : '',
              item.type === 'textarea' ? 'text' : '',
              item.type === 'video' ? 'video' : ''
            ]"
          >
            <p v-if="item.type === 'img'"><img :src="item.value" /></p>
            <p v-else-if="item.type === 'textarea'" v-html="item.value"></p>
            <p v-else-if="item.type === 'video'">
              <video
                :data-src="item.value"
                class="video-js"
                ref="videoPlayer"
                :poster="item.cover"
              ></video>
            </p>
          </div>
        </div>
        <div class="project-detail-params-container" v-if="params.length > 0">
          <div class="params-row" v-for="(row, index) in params" :key="index">
            <div class="param-item" v-for="(param, p) in row" :key="p">
              <div class="param-title">
                {{ param.title }}
              </div>
              <div class="param-val">
                <template v-if="param.content.indexOf('/n') === -1">
                  {{ param.content }}
                </template>
                <template v-else>
                  <p v-for="(p, pp) in param.content.split('/n')" :key="pp">{{ p }}</p>
                </template>
              </div>
            </div>
          </div>
        </div>
        <div class="mobile-project-detail-bts">
          <a
            :href="info.outLink"
            target="_blank"
            class="project__content-more-bt abt"
            v-if="info.outLink"
          >
            <div class="page-more-bt" :class="[!$isMobile ? 'hover' : '']"><p>more</p></div>
          </a>
          <div
            v-if="$isMobile"
            class="project__content-more-bt abt"
            @click="goBack(info.typeID)"
            :href="`/project-list/${info.typeID}?targetID=${id}`"
          >
            <div class="page-more-bt"><p>back</p></div>
          </div>
        </div>
        <Footer></Footer>
      </div>
    </div>
    <router-link
      class="sidebar-bt --prev js-fixed-global js-side-bar"
      tag="div"
      v-if="prev"
      :to="{
        path: `/project/${prev.id}`
      }"
    >
      <div class="inner-body">
        <p>prev project</p>
      </div>
    </router-link>
    <router-link
      class="sidebar-bt --next js-fixed-global js-side-bar"
      tag="div"
      v-if="next"
      :to="{
        path: `/project/${next.id}`
      }"
    >
      <div class="inner-body">
        <p>next project</p>
      </div>
    </router-link>
    <div v-if="$isMobile" class="sidebar-bt --top js-fixed-global js-side-bar" @click="goTop">
      <div class="inner-body">
        <p>top</p>
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { preloadImgs, scrollTo } from "@/assets/utils/common";
import Footer from "@/components/footer.vue";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import { mapMutations } from "vuex";

export default {
  components: {
    Footer
  },
  data() {
    return {
      info: null,
      imgQueue: 0,
      next: null,
      prev: null,
      players: [],
      playOptions: {
        muted: true,
        controls: true,
        autoplay: !this.$isMobile,
        loop: true,
        preload: "auto",
        playsinline: true
      }
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    params() {
      const temp = [];
      const { info } = this.info;
      if (info) {
        for (let i = 0; i < info.length; i += 1) {
          const index = i % 2;
          if (temp[index] === undefined) {
            temp[index] = [];
          }
          temp[index].push(info[i]);
        }
      }
      return temp;
    }
  },
  watch: {
    $route: {
      handler: "init",
      immediate: true
    }
  },
  beforeCreate() {},
  mounted() {},
  updated() {
    const that = this;
    that.$nextTick(() => {
      if (that.$refs.videoPlayer) {
        for (let i = 0; i < that.$refs.videoPlayer.length; i += 1) {
          const tempVideo = that.$refs.videoPlayer[i];
          const config = that.playOptions;
          config.sources = [
            {
              src: tempVideo.dataset.src,
              type: "video/mp4"
            }
          ];
          const player = videojs(tempVideo, config);
          that.players.push(player);
          if (!this.$isMobile) {
            player.play();
          }
        }
      }
    });
  },
  methods: {
    ...mapMutations(["setTargetID"]),
    goTop() {
      scrollTo();
    },
    async init() {
      this.$store.commit("openLoading");
      let imgs = [];
      this.info = null;
      this.players = [];
      try {
        const res = await this.$http.get(`/api/product/detail/${this.id}`);
        const data = res.data.case;
        this.next = res.data.next;
        this.prev = res.data.prev;
        if (this.$isMobile && data.inner_big instanceof Array) {
          imgs = imgs.concat(...data.inner_big);
        } else {
          imgs.push(data.inner_big);
        }

        let content;
        if (data.is_on_home === 2) {
          content = data.inner_big.map((item) => ({
            type: "img",
            value: item
          }));
        } else {
          content = JSON.parse(data.content);
          content.forEach((item) => {
            if (item.type === "img") {
              imgs.push(item.value);
            }
          });
        }

        const tempInfo = {
          name: JSON.parse(data.name),
          location: JSON.parse(data.location),
          inner_big: data.inner_big,
          content,
          info: data.info,
          outLink: data.out_link,
          typeID: data.type_id
        };
        this.info = tempInfo;
        await preloadImgs(imgs);
      } catch (e) {
        console.log(e);
      }
      this.$store.commit("closeLoading");
    },
    isFirstImg(item) {
      if (item.type === "img") {
        this.imgQueue += 1;
      }
      return {
        img: item.type === "img",
        text: item.type === "textarea",
        firstimg: item.type === "img" && this.imgQueue === 1
      };
    },
    parseHTML(item) {
      if (item.type === "img") {
        return `<p><img src="${item.value}" /></p>`;
      }
      if (item.type === "textarea") {
        // const reg = /<p>(.+)<\/p>/;
        // if (reg.test(item.value)) {
        //   return RegExp.$1;
        // }
        return item.value;
      }
      if (item.type === "video") {
        return `<video src="${item.value}" ref="videoPlayer" class="video-js"></video>`;
      }
      return item.value;
    },
    parseBR(val) {
      const str = String(val);
      return str;
    },
    goBack(typeID) {
      this.setTargetID(this.id);
      this.$router.push({
        path: `/project-list/${typeID}`
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.page-common-main-container {
  width: 100% !important;
}
</style>
