import Vue from 'vue';
import Vuex from 'vuex';
import http from '@/api/request';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isShowLoading: false,
    isLoaded: false,
    currentPage: '',
    typesList: null,
    backTargetID: 0,
    scrollBarInit: false,
  },
  mutations: {
    scrollBarHasInited(state) {
      state.scrollBarInit = true;
    },
    scrollBarPrepare(state) {
      state.scrollBarInit = false;
    },
    clearTargetID(state) {
      state.backTargetID = 0;
    },
    setTargetID(state, targetID) {
      state.backTargetID = targetID;
    },
    openLoading(state) {
      if (state.isShowLoading === false) {
        state.isShowLoading = true;
        state.isLoaded = false;
      }
    },
    closeLoading(state) {
      if (state.isShowLoading === true) {
        state.isShowLoading = false;
        state.isLoaded = true;
      }
    },
    setCurrentPage(state, val) {
      state.currentPage = val;
    },
    setTypesList(state, val) {
      state.typesList = val;
    },
  },
  actions: {
    types({ commit, state }) {
      return new Promise((resolve, reject) => {
        if (state.typesList === null) {
          http.get('/api/product/type-list').then((res) => {
            const { data } = res;
            let lists = null;
            if (data.lists.length > 0) {
              lists = data.lists.map((item) => {
                const temp = item;
                temp.name = JSON.parse(temp.name);
                return temp;
              });
              commit('setTypesList', lists);
            }
            resolve(lists);
          }).catch((err) => {
            reject(err);
          });
        } else {
          resolve(state.typesList);
        }
      });
    },
  },
  modules: {
  },
});
