<template>
  <div class="page-header-container">
    <template v-if="!$isMobile">
      <div class="site-header-fixed-container js-fixed-global js-menu-switch-container">
        <div class="logo" @click="gohome"></div>
        <div class="page-menu-container" :class="[isHome ? '--hidden' : '--show']">
          <div class="page-menu__burger burger-menu openBt">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
      <div class="home-menu-container" v-if="isHome">
        <ul class="home-menu-lists">
          <router-link
            tag="li"
            :to="{
              path: '/index.html',
              query: {
                type: link.id
              }
            }"
            v-for="(link, index) in homeMenu"
            :key="index"
            :class="{
              active: Number(type) === Number(link.id)
            }"
          >
            <p>{{ link.name.en }}</p>
            <p>{{ link.name.cn }}</p>
          </router-link>
        </ul>
      </div>
      <div class="page-menu-list-container js-fixed-global">
        <div class="page-menu-list-container__wrapper">
          <div class="page-menu-list-container__header js-menu-switch-container">
            <router-link tag="div" class="logo" to="/"></router-link>
            <div class="page-menu__burger close-menu closeBt">
              <div class="close-menu-icon"><span></span><span></span></div>
              <div class="__text">close</div>
            </div>
          </div>
          <ul class="__menu-main-body">
            <li class="__menu-item" v-for="(link, index) in pageMenu" :key="index">
              <router-link tag="a" :to="link.url">{{ link.name }}</router-link>
              <div class="hover-show-img-container">
                <div class="mask-wrapper">
                  <div class="img-container">
                    <img :src="link.img" alt="" />
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="site-header-4-mobile-container">
        <router-link
          tag="div"
          class="logo"
          :to="{
            path: '/'
          }"
        ></router-link>
        <div class="menu-container" @click="changeNav">
          <i></i>
          <i></i>
          <i></i>
        </div>
      </div>
      <div
        class="mobile-nav-container"
        :style="{
          height: `${screenHeight}px`
        }"
      >
      </div>
    </template>
  </div>
</template>

<script>
import { scrollTo } from '@/assets/utils/common';
import $ from 'jquery';

export default {
  data() {
    return {
      homeMenu: null,
      isIndex: false,
      type: null,
      mobileNavStatus: false,
    };
  },
  computed: {
    isHome() {
      return this.$route.name === 'index';
    },
    pageMenu() {
      return this.$isMobile
        ? [
          {
            name: 'project',
            url: '/project-list/1',
            img: '/static/images/m-n-1.jpg',
          },
          {
            name: 'about us',
            url: '/about.html',
            img: '/static/images/m-n-2.jpg',
          },
          {
            name: 'jobs',
            url: '/job.html',
            img: '/static/images/m-n-3.jpg',
          },
          {
            name: 'contact us',
            url: '/contact.html',
            img: '/static/images/m-n-4.jpg',
          },
        ]
        : [
          {
            name: 'project',
            url: '/index.html?type=1',
            img: '/static/images/menu-1.jpg',
          },
          {
            name: 'about us',
            url: '/about.html',
            img: '/static/images/menu-2.jpg',
          },
          {
            name: 'join us',
            url: '/job.html',
            img: '/static/images/menu-3.png',
          },
          {
            name: 'contact',
            url: '/contact.html',
            img: '/static/images/menu-4.png',
          },
        ];
    },
    currentPage() {
      return this.$store.state.currentPage;
    },
    getTypes() {
      return this.$store.state.typesList;
    },
    screenHeight() {
      return window.innerHeight;
    },
  },
  watch: {
    currentPage(val) {
      this.isIndex = val === 'index';
    },
    getTypes(val) {
      this.homeMenu = val;
      const { type } = this.$route.query;
      this.type = type === undefined ? val['0'].id : type;
    },
    $route: {
      handler: 'init',
      immediate: true,
    },
    mobileNavStatus(val) {
      if (val) {
        // 打开
        this.openNav();
      } else {
        // 关闭
        this.closeNav();
      }
    },
  },
  mounted() {
    console.log(this.$route.name, this.currentPage);
    this.isIndex = this.currentPage === 'index';
  },
  methods: {
    init(val) {
      this.mobileNavStatus = false;
      const { type } = val.query;
      this.type = type || 1;
    },
    changeNav() {
      this.mobileNavStatus = !this.mobileNavStatus;
    },
    openNav() {
      document.body.classList.add('open-nav');
      $('.mobile-nav-container').css({
        'z-index': 8,
      });
    },
    closeNav() {
      document.body.classList.remove('open-nav');
      setTimeout(() => {
        $('.mobile-nav-container').css({
          'z-index': -1,
        });
      }, 1000);
    },
    gohome() {
      if (this.$route.path === '/index.html') {
        scrollTo();
      } else {
        this.$router.push({
          path: '/index.html',
        });
      }
    },
  },
};
</script>

<style></style>
