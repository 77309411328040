<template>
  <div id="app">
    <Loading></Loading>
    <BaseLayout>
      <template v-slot:header>
        <Header></Header>
      </template>
      <template v-slot:main>
        <router-view v-if="isRouterAlive" />
      </template>
    </BaseLayout>
    <mobile-nav v-if="$isMobile"></mobile-nav>
  </div>
</template>
<script>
import BaseLayout from '@/components/baseLayout.vue';
import Header from '@/components/header.vue';
import Loading from '@/components/loading.vue';
import MobileNav from '@/components/mobile-nav.vue';

export default {
  components: {
    MobileNav,
    BaseLayout,
    Header,
    Loading,
  },
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isRouterAlive: true,
    };
  },
  created() {
    this.init();
  },
  mounted() {
    // 初始化
  },
  watch: {
    $route: 'init',
  },
  methods: {
    init() {
      this.getTypes();
    },
    getTypes() {
      this.$store.dispatch('types');
    },
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      });
    },
  },
};
</script>
