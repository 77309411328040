<template>
  <div class="scroll-wrapper">
      <div>
          <slot name="main"></slot>
      </div>
  </div>
</template>

<script>
export default {
  mounted() {
    console.log('scroll-wrapper');
  },
};
</script>

<style>

</style>
