<template>
  <div class="page-footer-container">
    <div class="page-icp-container" v-if="!$isMobile">
      Copyright Dejoy International Architects 丹健国际 版权所有 |
      <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">沪ICP备17010633号-1</a>
    </div>
    <template v-else>
      <div class="page-icp-container">
        <p>Copyright Dejoy International Architects 丹健国际 版权所有</p>
        <p>
          <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank"
            >沪ICP备17010633号-1</a
          >
        </p>
      </div>
    </template>
    <div class="page-author" v-if="!$isMobile && $route.path === '/index.html'">
      <a href="https://www.gooob.cn/" target="_blank">Web Design: Gooobrand</a>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
