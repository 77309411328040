<template>
  <div class="">
    <ScrollWrapper>
      <template v-slot:main>
        <div>1111</div>
      </template>
    </ScrollWrapper>
  </div>
</template>

<script>
// import $ from 'jquery';
// eslint-disable-next-line import/no-cycle
import { initScrollBar, initParallax } from '@/assets/utils/common';
import ScrollWrapper from '@/components/ScrollWrapper.vue';

export default {
  components: {
    ScrollWrapper,
  },
  data() {
    return {
      imgLists: [
        {
          src: '/images/01.jpg',
        },
        {
          src: '/images/03.jpg',
        },
        {
          src: '/images/05.jpg',
        },
        {
          src: '/images/06.jpg',
        },
      ],
    };
  },
  mounted() {
    initScrollBar();
    initParallax();
  },
};
</script>

<style lang="scss" scoped>
.img-list {
  width: 100%;
  min-height: 480px;
  height: 55vh;
  .img-item {
    width: calc(100% - 75px);
    height: calc(100% + 200px);
    position: relative;
    .img-item__picture {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      overflow: hidden;
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: auto;
      }
    }
  }
}
</style>
