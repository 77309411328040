<template>
  <div class="page-contact-container">
    <div class="contact-body-wrapper">
      <div class="page-title-4-mobile" v-if="$isMobile">contact us</div>
      <div class="contact-body-bone">
        <div class="side-contact-info-container">
          <div class="text-info-container">
            <div
              class="info-group scroll-up-to-show"
              v-for="(info, index) in contactInfo"
              :key="index"
            >
              <div class="info-title">
                {{ info.city.cn }} <span>{{ info.city.en }}</span>
              </div>
              <div class="info-main-body">
                <p class="info-text" v-for="(p, pindex) in info.address.split('\n')" :key="pindex">
                  {{ p }}
                </p>
                <p class="info-text --contacts">
                  联系人_{{ info.contacts.cn }} {{ info.contacts.en }}
                </p>
                <a class="info-text --email" :href="`mailto:${info.email}`"
                  >Email_{{ info.email }}</a
                >
                <a class="info-text" :href="`tel:${info.phone}`">TEL_{{ info.phone }}</a>
              </div>
            </div>
          </div>
          <div class="qr-container scroll-up-to-show">
            <div class="qr-item">
              <div class="title">Wechat</div>
              <div class="qr-img"><img src="/static/images/qr.png" alt="" /></div>
            </div>
          </div>
        </div>
        <div class="contact-main-info-container">
          <div class="title-container scroll-up-to-show text">contact us</div>
          <div class="bg-container scroll-up-to-show">
            <img src="/static/images/contact-bg.jpg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { preloadImgs } from "@/assets/utils/common";

export default {
  beforeCreate() {
    this.$store.commit("openLoading");
  },
  data() {
    return {
      contactInfo: [
        {
          city: {
            cn: "上海",
            en: "ShangHai"
          },
          address: "上海市宝山区真大路520号\n昇park创意园1号楼202室",
          contacts: {
            cn: "朱小姐",
            en: "ms. zhu"
          },
          email: "dia@diash.cn",
          phone: "(086)021-61313410"
        },
        {
          city: {
            cn: "深圳",
            en: "ShenZhen"
          },
          address: "深圳市福田保税区市花路8号\n和合大厦108室",
          contacts: {
            cn: "余小姐",
            en: "ms. yu"
          },
          email: "dia@diasz.cn",
          phone: "(086)0755-82565580"
        }
      ]
    };
  },
  async created() {
    const imgs = ["/static/images/contact-bg.jpg", "/static/images/qr.jpg"];
    try {
      await preloadImgs(imgs);
    } catch (e) {
      console.error(e);
    }
    this.$store.commit("closeLoading");
  }
};
</script>

<style></style>
