<template>
  <div class="mobile-nav-container">
    <ul
      class="nav__container"
      :class="[screenHeight < 530 ? 'ios5' : 'higherIos5']"
      :style="{
        height: `${screenHeight}px`
      }"
    >
      <li class="nav__item" v-for="(menu, index) in pageMenu" :key="index">
        <router-link
          :to="{
            path: menu.url
          }"
          class="nav__link"
        >
          <div
            class="nav__img"
            :style="{
              backgroundImage: `url(${menu.img})`
            }"
          ></div>
          <div class="nav__text">{{ menu.name }}</div>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'mobile-nav',
  data() {
    return {
      screenHeight: window.innerHeight,
    };
  },
  computed: {
    // screenHeight() {
    //   return window.innerHeight;
    // },
    pageMenu() {
      return this.$isMobile
        ? [
          {
            name: 'project',
            url: '/project-list/1',
            img: '/static/images/m-n-1.jpg',
          },
          {
            name: 'about us',
            url: '/about.html',
            img: '/static/images/m-n-2.jpg',
          },
          {
            name: 'jobs',
            url: '/job.html',
            img: '/static/images/m-n-3.jpg',
          },
          {
            name: 'contact us',
            url: '/contact.html',
            img: '/static/images/m-n-4.jpg',
          },
        ]
        : [
          {
            name: 'project',
            url: '/index.html?type=1',
            img: '/static/images/menu-1.jpg',
          },
          {
            name: 'about us',
            url: '/about.html',
            img: '/static/images/menu-2.jpg',
          },
          {
            name: 'join us',
            url: '/job.html',
            img: '/static/images/menu-3.png',
          },
          {
            name: 'contact',
            url: '/contact.html',
            img: '/static/images/menu-4.png',
          },
        ];
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.screenHeight -= document.querySelector('.header').getBoundingClientRect().height;
    });
  },
};
</script>

<style></style>
