import axios from 'axios';

axios.defaults.timeout = 8000;
const baseURL = process.env.NODE_ENV === 'development' ? 'https://api.diash.cn' : 'https://api.diash.cn';
axios.defaults.baseURL = baseURL;

axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';

axios.interceptors.request.use(
  (config) => config,
  (err) => Promise.reject(err),
);

axios.interceptors.response.use(
  (response) => response.data,
  (err) => Promise.reject(err),
);

export default axios;
