<template>
  <div class="simple-detail-container" v-if="project">
    <div class="simple-detail-swiper-container">
      <div class="swiper-direction-bts" v-if="!1">
        <div class="swiper-button-prev --direction-bt"></div>
        <div class="swiper-button-next --direction-bt"></div>
      </div>
      <div class="simple-project-info-container">
        <div class="info__main-body">
          <div class="info__grid --project-head-bts">
            <div @click="goback" class="scroll-up-to-show text">go back</div>
          </div>
          <div class="info__grid --project-name">
            <p class="scroll-up-to-show text">{{ project.name.cn }}</p>
            <p class="scroll-up-to-show text">{{ project.name.en }}</p>
          </div>
          <div class="info__grid --project-direction-bts">
            <router-link
              v-if="prev"
              tag="div"
              :to="{
                path: `/project-simple/${prev.id}`
              }"
              class="scroll-up-to-show text"
              >PREV Project</router-link
            >
            <router-link
              v-if="next"
              tag="div"
              :to="{
                path: `/project-simple/${next.id}`
              }"
              class="scroll-up-to-show text"
              >NEXT Project</router-link
            >
            <a
              :href="project.out_link"
              target="_blank"
              v-if="project.out_link"
              class="scroll-up-to-show text"
              >MORE</a
            >
          </div>
        </div>
      </div>
      <div class="simple-project-img-container">
        <div class="img__main-body">
          <div class="img__title scroll-up-to-show text">
            <div class="title__main-body"></div>
          </div>
          <div class="img__swiper-container scroll-up-to-show img">
            <swiper class="img__swiper-list" :options="swiperOptions" ref="simpleSwiper">
              <swiper-slide
                class="img__swiper-item"
                v-for="(img, index) in project.inner_big"
                :key="index"
              >
                <div
                  class="img-container"
                  :style="{
                    backgroundImage: `url(${img})`
                  }"
                ></div>
              </swiper-slide>
            </swiper>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
// eslint-disable-next-line import/no-cycle
import { preloadImgs } from '@/assets/utils/common';

export default {
  watch: {
    $route: {
      handler: 'init',
      immediate: true,
    },
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  // created() {
  //   setTimeout(() => {
  //     this.$store.commit('closeLoading');
  //   }, 1000);
  // },
  data() {
    return {
      swiperOptions: {
        autoplay: {
          delay: 3000,
        },
        spaceBetween: 0,
        watchSlidesProgress: true,
        speed: 1000,
        effect: 'fade',
        loop: true,
        pagination: {
          el: '.img__title .title__main-body',
          type: 'fraction',
          renderFraction(currentClass, totalClass) {
            return `<span class="${currentClass}"></span>/<span class="${totalClass}"></span>`;
          },
          formatFractionCurrent(number) {
            return number < 10 ? `0${number}` : number;
          },
          formatFractionTotal(number) {
            return number < 10 ? `0${number}` : number;
          },
        },
        init: false,
      },
      project: null,
      prev: null,
      next: null,
    };
  },
  methods: {
    async init() {
      const that = this;
      that.project = null;
      that.$store.commit('openLoading');
      const res = await that.$http.get(`/api/product/detail/${that.id}`);
      that.project = res.data.case;
      that.prev = res.data.prev;
      that.next = res.data.next;
      await preloadImgs(that.project.inner_big);
      that.project.name = JSON.parse(that.project.name);
      that.project.location = JSON.parse(that.project.location);
      that.$store.commit('closeLoading');
      that.$refs.simpleSwiper.$swiper.init();
    },
    goback() {
      this.$router.go(-1);
    },
  },
};
</script>

<style></style>
