import Vue from 'vue';
import http from '@/api/request';
import Tool from '@/assets/utils/tool';
import ElementUI from 'element-ui';
import bowser from 'bowser';
import App from './App.vue';
import router from './router';
import store from './store';
import 'element-ui/lib/theme-chalk/index.css';
import 'normalize.css';
import './assets/scss/common.scss';

Vue.config.productionTip = false;

Vue.prototype.$http = http;

Vue.prototype.global = {
  isShowLoading: false,
};

Vue.prototype.Tool = Tool;
Vue.prototype.$isMobile = bowser.mobile;

Vue.use(ElementUI);

const ieVersion = Tool.IEVersion();

if (ieVersion === -1) {
  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount('#app');
} else {
  const hintContainer = document.getElementById('browser-ie-hint-container');
  hintContainer.style.display = 'block';
  hintContainer.querySelector('.version').innerHTML = ieVersion;
}
