<template>
  <div class="simple-project-list-container">
    <div class="page-title js-fixed-global" v-if="currentType">
      <p>More<br />{{ currentType.name.en }}</p>
      <p>{{ lists.length }}</p>
    </div>
    <div class="page-title-4-mobile" v-if="$isMobile && currentType">More {{ currentType.name.en }}</div>
    <div class="simple-project-wrapper">
      <div class="simple-project-wrapper__main-body">
        <router-link
          class="simple-item scroll-up-to-show"
          v-for="(item, index) in lists"
          :key="index"
          tag="div"
          :to="{
            path: `${$isMobile ? `/project/${item.id}` : `/project-simple/${item.id}`}`
          }"
        >
          <div class="simple-item__pic">
            <img :src="item.cover" alt="" />
          </div>
          <div class="simple-item__text">
            <p class="en">{{ item.name.en }}</p>
            <p class="cn">{{ item.name.cn }}</p>
          </div>
        </router-link>
      </div>
      <div class="home-project-wrapper__more-bt" v-if="!$isMobile">
        <div class="bt-desc-line --back"></div>
        <router-link tag="div" class="text" to="/">go back</router-link>
      </div>
      <router-link tag="div" to="/" class="mobile-more-back-bt scroll-up-to-show text" v-else>
        <div class="text">go back</div>
      </router-link>
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import Footer from '@/components/footer.vue';
// eslint-disable-next-line import/no-cycle
import { preloadImgs, waterfallLayout } from '@/assets/utils/common';

export default {
  components: {
    Footer,
  },
  data() {
    return {
      lists: [],
      preload: false,
      // currentType: null,
    };
  },
  computed: {
    typeLists() {
      return this.$store.state.typesList;
    },
    currentType() {
      if (this.typeLists) {
        const type = this.typeLists.filter((item) => item.id === this.$route.params.type);
        return type['0'];
      }
      return null;
    },
  },
  beforeCreate() {},
  watch: {
    typeLists() {
      // console.log(val);
      // const type = val.filter((item) => item.id === this.$route.params.type);
      // this.currentType = type['0'];
    },
    preload(val) {
      if (val) {
        this.layout();
      }
    },
    $route: {
      handler: 'init',
      immediate: true,
    },
  },
  mounted() {},
  methods: {
    async init() {
      const that = this;
      that.$store.commit('openLoading');
      const t1 = new Date().getTime();
      const res = await that.$http.get('/api/product/list', {
        page: 0,
        type: that.$route.params.type,
        is_on_home: 2,
      });
      const { lists } = res.data;
      const imgs = [];
      lists.map((item) => {
        const temp = item;
        imgs.push(temp.cover);
        temp.location = JSON.parse(temp.location);
        temp.name = JSON.parse(temp.name);
        return temp;
      });
      await preloadImgs(imgs);
      that.lists = lists;
      that.preload = true;
      const t2 = new Date().getTime();
      if (t2 - t1 <= 500) {
        setTimeout(() => {
          that.$store.commit('closeLoading');
        }, 1000);
      } else {
        that.$store.commit('closeLoading');
      }
    },
    layout() {
      this.$nextTick(async () => {
        if (!this.$isMobile) {
          setTimeout(() => {
            waterfallLayout('simple-project-wrapper__main-body', 'simple-item');
          }, 0);
        }
      });
    },
  },
};
</script>

<style></style>
