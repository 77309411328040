const tool = {
  isPc() {
    const ua = navigator.userAgent;
    const isWindowsPhone = /(?:Windows Phone)/.test(ua);
    const isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone;
    const isAndroid = /(?:Android)/.test(ua);
    const isFireFox = /(?:Firefox)/.test(ua);
    // const isChrome = /(?:Chrome|CriOS)/.test(ua);
    const isTablet = /(?:iPad|PlayBook)/.test(ua) || (isAndroid && !/(?:Mobile)/.test(ua)) || (isFireFox && /(?:Tablet)/.test(ua));
    const isPhone = /(?:iPhone)/.test(ua) && !isTablet;
    const isPc = !isPhone && !isAndroid && !isSymbian;
    // return {
    //   isTablet,
    //   isPhone,
    //   isAndroid,
    //   isPc,
    //   isChrome,
    // };
    return isPc;
  },
  nl2br(str, isHtml) {
    if (typeof str === 'undefined' || str === null) {
      return '';
    }
    const breakTag = (isHtml || typeof (isHtml === 'undefined')) ? '<br/>' : '<br>';
    return (`${str}`).replace(/([^>\\r\\n]?)(\\r\\n|\\n\\r|\\r|\\n)/g, breakTag);
  },
  IEVersion() {
    const { userAgent } = navigator; // 取得浏览器的userAgent字符串
    // 判断是否IE<11浏览器
    const isIE = userAgent.indexOf('compatible') > -1 && userAgent.indexOf('MSIE') > -1;
    const isEdge = userAgent.indexOf('Edge') > -1 && !isIE; // 判断是否IE的Edge浏览器
    const isIE11 = userAgent.indexOf('Trident') > -1 && userAgent.indexOf('rv:11.0') > -1;
    if (isIE) {
      const reIE = new RegExp('MSIE (\\d+\\.\\d+);');
      reIE.test(userAgent);
      const fIEVersion = parseFloat(RegExp.$1);
      if (fIEVersion === 7) {
        return 7;
      } if (fIEVersion === 8) {
        return 8;
      } if (fIEVersion === 9) {
        return 9;
      } if (fIEVersion === 10) {
        return 10;
      }
      return 6;// IE版本<=7
    } if (isEdge) {
      // return 'edge';// edge
      return -1;
    } if (isIE11) {
      return 11; // IE11
    }
    return -1;// 不是ie浏览器
  },
};

export default tool;
