var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-header-container"},[(!_vm.$isMobile)?[_c('div',{staticClass:"site-header-fixed-container js-fixed-global js-menu-switch-container"},[_c('div',{staticClass:"logo",on:{"click":_vm.gohome}}),_c('div',{staticClass:"page-menu-container",class:[_vm.isHome ? '--hidden' : '--show']},[_vm._m(0)])]),(_vm.isHome)?_c('div',{staticClass:"home-menu-container"},[_c('ul',{staticClass:"home-menu-lists"},_vm._l((_vm.homeMenu),function(link,index){return _c('router-link',{key:index,class:{
            active: Number(_vm.type) === Number(link.id)
          },attrs:{"tag":"li","to":{
            path: '/index.html',
            query: {
              type: link.id
            }
          }}},[_c('p',[_vm._v(_vm._s(link.name.en))]),_c('p',[_vm._v(_vm._s(link.name.cn))])])}),1)]):_vm._e(),_c('div',{staticClass:"page-menu-list-container js-fixed-global"},[_c('div',{staticClass:"page-menu-list-container__wrapper"},[_c('div',{staticClass:"page-menu-list-container__header js-menu-switch-container"},[_c('router-link',{staticClass:"logo",attrs:{"tag":"div","to":"/"}}),_vm._m(1)],1),_c('ul',{staticClass:"__menu-main-body"},_vm._l((_vm.pageMenu),function(link,index){return _c('li',{key:index,staticClass:"__menu-item"},[_c('router-link',{attrs:{"tag":"a","to":link.url}},[_vm._v(_vm._s(link.name))]),_c('div',{staticClass:"hover-show-img-container"},[_c('div',{staticClass:"mask-wrapper"},[_c('div',{staticClass:"img-container"},[_c('img',{attrs:{"src":link.img,"alt":""}})])])])],1)}),0)])])]:[_c('div',{staticClass:"site-header-4-mobile-container"},[_c('router-link',{staticClass:"logo",attrs:{"tag":"div","to":{
          path: '/'
        }}}),_c('div',{staticClass:"menu-container",on:{"click":_vm.changeNav}},[_c('i'),_c('i'),_c('i')])],1),_c('div',{staticClass:"mobile-nav-container",style:({
        height: (_vm.screenHeight + "px")
      })})]],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-menu__burger burger-menu openBt"},[_c('span'),_c('span'),_c('span')])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-menu__burger close-menu closeBt"},[_c('div',{staticClass:"close-menu-icon"},[_c('span'),_c('span')]),_c('div',{staticClass:"__text"},[_vm._v("close")])])}]

export { render, staticRenderFns }