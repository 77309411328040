import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import WebIndex from '@/views/Web/Index.vue';
import Index from '@/pages/home/index.vue';
import ProjectList from '@/pages/project/list.vue';
import SimpleList from '@/pages/project/simple-list.vue';
import ProjectDetail from '@/pages/project/detail.vue';
import About from '@/pages/about/index.vue';
import Contact from '@/pages/contact/index.vue';
import Job from '@/pages/job/index.vue';
import SimpleDetail from '@/pages/project/simple.vue';
import Home from '../views/Home.vue';

const ua = window.navigator.userAgent.toLowerCase();
const isWeiXin = ua.match(/micromessenger/i) || ua.match(/wechatdevtools/i);

Vue.use(VueRouter);

const routes = [
  {
    path: '/home',
    name: 'Home',
    component: Home,
  },
  {
    path: '/test-about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '/',
    redirect: {
      name: 'index',
    },
  },
  {
    path: '/web-home',
    name: 'WebHome',
    component: WebIndex,
  },
  {
    path: '/index.html',
    name: 'index',
    component: Index,
    props: {
      type: true,
    },
  },
  {
    path: '/project-list/:type',
    name: 'project-list',
    props: true,
    component: ProjectList,
  },
  {
    path: '/project-simple-list/:type',
    name: 'simple-list',
    component: SimpleList,
  },
  {
    path: '/project-simple/:id',
    name: 'simple-detail',
    component: SimpleDetail,
  },
  {
    path: '/project/:id',
    name: 'detail',
    component: ProjectDetail,
    props: true,
  },
  {
    path: '/about.html',
    name: 'about',
    component: About,
    meta: {
      headerFixed: true,
    },
  },
  {
    path: '/contact.html',
    name: 'contact',
    component: Contact,
  },
  {
    path: '/job.html',
    name: 'job',
    component: Job,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    // to, from, savePosition
    return {
      x: 0,
      y: 0,
    };
  },
});

let toURL = null;
router.beforeEach((to, from, next) => {
  const toName = to.name;
  store.commit('setCurrentPage', toName);
  if (isWeiXin) {
    if (toURL === to.path) {
      next();
    }
    if (to.path !== from.path) {
      toURL = to.path;
      // router.replace(to.path);
      next();
    }
  } else {
    next();
  }
});

export default router;
