<template>
  <div class="page-about-container">
    <div class="page-about-container__main-body">
      <template v-if="!$isMobile">
        <div class="space-block">
          <div class="js-fixed-global --black fixed-title">Dejoy International Architects</div>
        </div>
        <!-- js-shrink-on-scroll -->
        <div class="about-bg">
          <div class="js-fixed-global --white fixed-title" data-pause>
            Dejoy International Architects
          </div>
          <div class="site-header-fixed-container js-fixed-global js-menu-switch-container">
            <router-link tag="div" class="logo" to="/"></router-link>
            <div class="page-menu-container --show">
              <div class="page-menu__burger burger-menu openBt">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
          <div class="about-bg__pic">
            <img src="/static/images/about-bg-3.jpg" alt="" />
          </div>
          <div class="cover-stripe cover-stripe--left"></div>
          <div class="cover-stripe cover-stripe--right"></div>
        </div>
        <div class="about-content">
          <div class="about-content__wrapper" id="about-content__wrapper">
            <p>
              DIA丹健国际是专注高端地产室内设计的国际化设计团队，在深圳、上海均设有创作团队。DIA主要从事高端酒店、办公、住宅、会所、商业空间等公共空间的室内设计，是华润、招商、绿城、华侨城、融创等一线地产品牌长期战略合作伙伴。拥有如上海苏河湾、北京东直门8号、华润瑞府悦府等系列、融创壹号院系列、深圳前海双子湾等一系列重量级作品。DIA自成立起一直坚持自我、坚守品质，并以其良好的信誉和稳健的作风赢得了业内一致赞誉。
            </p>
            <p>
              Dejoy International Architects (DlA) is an international design team focusing on
              high-end real estate interior design. We have design teams in Shenzhen and Shanghai in
              China. DIA mainly focuses on design for public space such as premium hotel, office,
              residence, club and commercial space. We have a long term partnership with CRland,
              Merchants, Greentown, OCT, Sunac and so on. Our works include a series of master
              pieces in Top cities like, Shanghai Suhe creek, Beijing Dongzhimen 8，CRland
              Ruifu&Yuefu seires, One Sino Park series of Sunac, Shenzhen The Twin Towers and so on.
              Due to the great reputation and steady style of work since its establishment, DIA has
              already gained remarkable testimonial in the interior design area. Hope that we will
              have the opportunity of cooperation in the near future.
            </p>
            <div class="logo-container">
              <div class="group" v-for="(group, index) in awards" :key="index">
                <div class="logo-item scroll-up-to-show" v-for="(logo, i) in group.imgs" :key="i">
                  <img :src="logo.src" alt="" :width="logo.width / 2" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div
          class="mobile-about-fix-bg"
          :style="{
            height: `${height}px`
          }"
        >
          <div class="img-box js-fixed-global"></div>
        </div>
        <div class="mobile-about-text-container">
          <div
            class="text__title"
            id="about_text__title"
            ref="mobile-about-text-container"
            :style="{
              height: `${innerHeight}px`
            }"
          >
            <p>Dejoy International Architects</p>
            <div class="line"></div>
          </div>
          <div
            class="text__content"
            :style="{
              height: `${innerHeight}px`
            }"
          >
            <div class="content__wrapper">
              <p class="content__title scroll-up-to-show text" style="display:none;">
                Dejoy International<br />Architects
              </p>
              <p class="content__text scroll-up-to-show text">
                DIA丹健国际是专注高端地产室内设计的国际化设计团队，在深圳、上海均设有创作团队。DIA主要从事高端酒店、办公、住宅、会所、商业空间等公共空间的室内设计，是华润、招商、绿城、华侨城、融创等一线地产品牌长期战略合作伙伴。拥有如上海苏河湾、北京东直门8号、华润瑞府悦府等系列、融创壹号院系列、深圳前海双子湾等一系列重量级作品。DIA自成立起一直坚持自我、坚守品质，并以其良好的信誉和稳健的作风赢得了业内一致赞誉。
              </p>
              <p class="content__text scroll-up-to-show text">
                Dejoy International Architects (DlA) is an international design team focusing on
                high-end real estate interior design. We have design teams in Shenzhen and Shanghai
                in China. DIA mainly focuses on design for public space such as premium hotel,
                office, residence, club and commercial space. We have a long term partnership with
                CRland, Merchants, Greentown, OCT, Sunac and so on. Our works include a series of
                master pieces in Top cities like, Shanghai Suhe creek, Beijing Dongzhimen 8，CRland
                Ruifu&Yuefu seires, One Sino Park series of Sunac, Shenzhen The Twin Towers and so
                on. Due to the great reputation and steady style of work since its establishment,
                DIA has already gained remarkable testimonial in the interior design area. Hope that
                we will have the opportunity of cooperation in the near future.
              </p>
            </div>
            <div class="logo-container">
              <div class="group" v-for="(group, index) in awards" :key="index">
                <div class="logo-item scroll-up-to-show" v-for="(logo, i) in group.imgs" :key="i">
                  <img :src="logo.src" alt="" :width="logo.width / 2" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { preloadImgs } from "@/assets/utils/common";

export default {
  data() {
    return {
      scrollTop: 0,
      innerHeight: 0,
      height: window.innerHeight,
      xh: 0,
      awards: [
        {
          imgs: [
            {
              // eslint-disable-next-line global-require
              src: require("@/assets/images/a-2-1.png"),
              width: 211
            },
            {
              // eslint-disable-next-line global-require
              src: require("@/assets/images/a-2-2.png"),
              width: 114
            },
            {
              // eslint-disable-next-line global-require
              src: require("@/assets/images/a-2-3.png"),
              width: 114
            },
            {
              // eslint-disable-next-line global-require
              src: require("@/assets/images/a-2-4.png"),
              width: 225
            }
          ]
        },
        {
          imgs: [
            {
              // eslint-disable-next-line global-require
              src: require("@/assets/images/about-award-1.png"),
              width: 211
            },
            {
              // eslint-disable-next-line global-require
              src: require("@/assets/images/about-award-2.png"),
              width: 114
            },
            {
              // eslint-disable-next-line global-require
              src: require("@/assets/images/about-award-3.png"),
              width: 114
            },
            {
              // eslint-disable-next-line global-require
              src: require("@/assets/images/about-award-4.png"),
              width: 225
            }
          ]
        },
        {
          imgs: [
            {
              // eslint-disable-next-line global-require
              src: require("@/assets/images/about-award-2-1.png"),
              width: 211
            },
            {
              // eslint-disable-next-line global-require
              src: require("@/assets/images/about-award-2-2.png"),
              width: 114
            },
            {
              // eslint-disable-next-line global-require
              src: require("@/assets/images/about-award-2-3.png"),
              width: 114
            },
            {
              // eslint-disable-next-line global-require
              src: require("@/assets/images/about-award-2-4.png"),
              width: 225
            }
          ]
        }
      ]
    };
  },
  watch: {
    scrollTop(val) {
      this.innerHeight = window.innerHeight - val;
    }
  },
  beforeCreate() {},
  async created() {
    const that = this;
    this.$store.commit("openLoading");
    const t1 = new Date().getTime();
    const imgs = [
      "/static/images/about-bg.png",
      // eslint-disable-next-line global-require
      require("@/assets/images/ma-bg.jpg")
    ];
    try {
      await preloadImgs(imgs);
    } catch (e) {
      console.log(e);
    }
    const t2 = new Date().getTime();
    if (t2 - t1 <= 500) {
      setTimeout(() => {
        that.$store.commit("closeLoading");
      }, 1000);
    } else {
      that.$store.commit("closeLoading");
    }
    this.$store.commit("closeLoading");
  },
  mounted() {
    if (this.$isMobile) {
      this.scrollTop = this.$refs["mobile-about-text-container"].getBoundingClientRect().top;
      this.xh = this.$refs["mobile-about-text-container"].getBoundingClientRect().height;
    }
  }
};
</script>

<style></style>
