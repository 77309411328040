<template>
  <div
    class="loading-container"
    :class="[loadingStatus ? '--open' : '--close']"
    :style="{
      height: `${height}px`
    }"
  >
    <div class="loading-container__main-body">
      <div class="loading-container-main-body__box">
        <div class="loading-logo"></div>
        <div class="loading-bar"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { initScrollBar, pageLoading } from '@/assets/utils/common';

export default {
  computed: {
    loadingStatus() {
      return this.$store.state.isShowLoading;
    },
    isLoaded() {
      return this.$store.state.isLoaded;
    },
    height() {
      return window.innerHeight;
    },
  },
  watch: {
    isLoaded: {
      handler(val) {
        if (val) {
          // console.log('完毕');
          this.$nextTick(() => {
            initScrollBar();
          });
        } else {
          this.$nextTick(() => {
            pageLoading();
          });
        }
      },
      immediate: true,
    },
  },
  mounted() {
    // console.log(this.isLoaded);
    // if (this.isLoaded) {
    //   this.$nextTick(() => {
    //     initScrollBar();
    //   });
    // }
    // console.log(this.isLoaded);
  },
  updated() {
    // this.$nextTick(() => {
    //   initScrollBar();
    // });
  },
};
</script>

<style></style>
