var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.info)?_c('div',{staticClass:"page-project-detail-container"},[_c('div',{staticClass:"project-title-container"},[_c('div',{staticClass:"project-title-container__title"},[_c('p',[_vm._v(_vm._s(_vm.info.name.cn))]),_c('p',[_vm._v(_vm._s(_vm.info.name.en))])]),_c('div',{staticClass:"project-location"},[_vm._v(_vm._s(_vm.info.location.en)+" | "+_vm._s(_vm.info.location.cn))])]),(!_vm.$isMobile)?_c('div',{staticClass:"project-detail-cover-container"},[_c('div',{staticClass:"project-detail-cover-container__pic_box parallax-container"},[_c('div',{staticClass:"cover-stripe cover-stripe--left"}),_c('div',{staticClass:"cover-stripe cover-stripe--right"}),_c('img',{attrs:{"src":_vm.info.inner_big,"alt":""}})]),_c('div',{staticClass:"site-header-fixed-container js-fixed-global js-menu-switch-container"},[_c('router-link',{staticClass:"logo",attrs:{"tag":"div","to":"/"}})],1)]):_vm._e(),_c('div',{staticClass:"project-detail-content-container"},[_c('div',{staticClass:"project-detail-content–container__main-body"},[_c('div',{staticClass:"__content"},_vm._l((_vm.info.content),function(item,index){return _c('div',{key:index,staticClass:"scroll-up-to-show",class:[
            item.type === 'img' ? 'img' : '',
            item.type === 'textarea' ? 'text' : '',
            item.type === 'video' ? 'video' : ''
          ]},[(item.type === 'img')?_c('p',[_c('img',{attrs:{"src":item.value}})]):(item.type === 'textarea')?_c('p',{domProps:{"innerHTML":_vm._s(item.value)}}):(item.type === 'video')?_c('p',[_c('video',{ref:"videoPlayer",refInFor:true,staticClass:"video-js",attrs:{"data-src":item.value,"poster":item.cover}})]):_vm._e()])}),0),(_vm.params.length > 0)?_c('div',{staticClass:"project-detail-params-container"},_vm._l((_vm.params),function(row,index){return _c('div',{key:index,staticClass:"params-row"},_vm._l((row),function(param,p){return _c('div',{key:p,staticClass:"param-item"},[_c('div',{staticClass:"param-title"},[_vm._v(" "+_vm._s(param.title)+" ")]),_c('div',{staticClass:"param-val"},[(param.content.indexOf('/n') === -1)?[_vm._v(" "+_vm._s(param.content)+" ")]:_vm._l((param.content.split('/n')),function(p,pp){return _c('p',{key:pp},[_vm._v(_vm._s(p))])})],2)])}),0)}),0):_vm._e(),_c('div',{staticClass:"mobile-project-detail-bts"},[(_vm.info.outLink)?_c('a',{staticClass:"project__content-more-bt abt",attrs:{"href":_vm.info.outLink,"target":"_blank"}},[_c('div',{staticClass:"page-more-bt",class:[!_vm.$isMobile ? 'hover' : '']},[_c('p',[_vm._v("more")])])]):_vm._e(),(_vm.$isMobile)?_c('div',{staticClass:"project__content-more-bt abt",attrs:{"href":("/project-list/" + (_vm.info.typeID) + "?targetID=" + _vm.id)},on:{"click":function($event){return _vm.goBack(_vm.info.typeID)}}},[_vm._m(0)]):_vm._e()]),_c('Footer')],1)]),(_vm.prev)?_c('router-link',{staticClass:"sidebar-bt --prev js-fixed-global js-side-bar",attrs:{"tag":"div","to":{
      path: ("/project/" + (_vm.prev.id))
    }}},[_c('div',{staticClass:"inner-body"},[_c('p',[_vm._v("prev project")])])]):_vm._e(),(_vm.next)?_c('router-link',{staticClass:"sidebar-bt --next js-fixed-global js-side-bar",attrs:{"tag":"div","to":{
      path: ("/project/" + (_vm.next.id))
    }}},[_c('div',{staticClass:"inner-body"},[_c('p',[_vm._v("next project")])])]):_vm._e(),(_vm.$isMobile)?_c('div',{staticClass:"sidebar-bt --top js-fixed-global js-side-bar",on:{"click":_vm.goTop}},[_vm._m(1)]):_vm._e()],1):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-more-bt"},[_c('p',[_vm._v("back")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"inner-body"},[_c('p',[_vm._v("top")])])}]

export { render, staticRenderFns }