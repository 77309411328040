<template>
  <div
    class="page-home-container"
    :style="{
      height: `${innerHeight}px`
    }"
  >
    <div class="home-scroll-container fancy-slider">
      <div class="fancy-wrapper">
        <swiper
          class="fancy-slider__list"
          :options="swiperOptions"
          ref="homeSwiper"
          v-if="scrolls.length > 0"
        >
          <swiper-slide class="fancy-slider__item" v-for="(scroll, index) in scrolls" :key="index">
            <a class="slide-inner" :href="scroll.url">
              <div class="fancy-slider-item__img-box parallax-container">
                <img :src="scroll.img" alt="" />
              </div>
              <div class="fancy-slider-item__content">
                <div class="item-content__wrapper">
                  <div class="item__content">
                    <p>
                      {{ scroll.title.split("/n")["0"] }}
                      <span>{{ scroll.title.split("/n")["1"] }}</span>
                    </p>
                    <p v-if="$isMobile">{{ scroll.title.split("/n")["1"] }}</p>
                  </div>
                </div>
              </div>
            </a>
          </swiper-slide>
        </swiper>
        <div class="fancy-slider__counter">
          <div class="nub-position">
            <p class="__current">01</p>
            <p class="__next">02</p>
          </div>
          <div class="line">
            <div class="inner"></div>
          </div>
          <div class="total">
            <template v-if="scrolls.length >= 10">{{ scrolls.length }}</template>
            <template v-else>0{{ scrolls.length }}</template>
          </div>
        </div>
        <div class="fancy-slider__arrow"></div>
      </div>
      <div class="site-header-fixed-container js-fixed-global js-menu-switch-container">
        <router-link tag="div" class="logo" to="/"></router-link>
      </div>
    </div>
    <div v-if="!$isMobile" class="home-project-list-container" id="home-project-list-container">
      <div
        class="home-project-list-container__menu-container js-fixed-global"
        style="overflow:auto"
      >
        <ul class="menu-container scroll-up-to-show" v-if="getTypes">
          <li
            v-for="(menu, index) in getTypes"
            :key="index"
            :class="[Number(menu.id) === Number(type) ? 'active' : '']"
            @click="switchType(menu.id)"
          >
            <p class="title">{{ menu.name.en }}</p>
            <p class="cn">{{ menu.name.cn }}</p>
          </li>
        </ul>
      </div>
      <div class="home-project-wrapper">
        <div class="wrapperccc">
          <div class="home-project-wrapper__main-container" v-if="cases.length > 0">
            <router-link
              class="project-group"
              v-for="(item, index) in cases"
              :key="index"
              tag="div"
              :to="`/project/${item.id}`"
            >
              <div class="project-group__case scroll-up-to-show">
                <div class="project-group-case__item">
                  <img class="lazyload" :src="item.cover" alt="" :data-src="item.cover" />
                  <div class="text">
                    {{ item.location.en }} {{ item.title.en }} | {{ item.location.cn }}
                    {{ item.title.cn }}
                  </div>
                </div>
              </div>
            </router-link>
          </div>
          <div class="" v-else>暂无数据</div>
          <div class="home-project-wrapper__more-bt" style="display:none;">
            <div class="bt-desc-line"></div>
            <router-link tag="div" class="text" to="/project-simple-list"
              >more projects</router-link
            >
          </div>
          <div class="page-more-bt-container" v-if="hasMore > 0">
            <router-link
              tag="div"
              class="page-more-bt"
              :class="[!$isMobile ? 'hover' : '']"
              :to="{
                path: `/project-simple-list/${type}`
              }"
              ><p>more projects</p></router-link
            >
          </div>
          <Footer></Footer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Footer from '@/components/footer.vue';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
import { TweenMax } from 'gsap';
import $ from 'jquery';
// eslint-disable-next-line import/no-cycle
import { preloadDom } from '@/assets/utils/common';

export default {
  components: {
    Footer,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperOptions: {
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        loop: true,
        speed: 1000,
        grabCursor: false,
        watchSlidesProgress: true,
        mousewheelControl: true,
        keyboardControl: true,
        on: {
          progress() {
            const swiper = this;
            const interleaveOffset = 0.5;
            for (let i = 0; i < swiper.slides.length; i += 1) {
              const slideProgress = swiper.slides[i].progress;
              const innerOffset = swiper.width * interleaveOffset;
              const innerTranslate = slideProgress * innerOffset;
              swiper.slides[i].querySelector(
                '.slide-inner',
              ).style.transform = `translate3d(${innerTranslate}px, 0, 0)`;
            }
          },
          touchStart() {
            const swiper = this;
            for (let i = 0; i < swiper.slides.length; i += 1) {
              swiper.slides[i].style.transition = '';
            }
          },
          setTransition(speed) {
            const swiper = this;
            for (let i = 0; i < swiper.slides.length; i += 1) {
              swiper.slides[i].style.transition = `${speed}ms`;
              swiper.slides[i].querySelector('.slide-inner').style.transition = `${speed}ms`;
            }
          },
          transitionStart() {
            const swiper = this;
            const $counter = $('.nub-position');
            const $current = $('.__current');
            const $next = $('.__next');
            const { delay } = swiper.passedParams.autoplay;
            const str = `0${swiper.realIndex + 1}`;
            const strxx = swiper.realIndex === 0 ? '01' : `0${swiper.realIndex}`;

            $current.text(str);
            $next.text(strxx);

            TweenMax.fromTo(
              $current,
              1,
              {
                opacity: 0,
              },
              {
                opacity: 1,
              },
            );
            TweenMax.fromTo(
              $next,
              1,
              {
                opacity: 1,
              },
              {
                opacity: 0,
              },
            );
            TweenMax.fromTo(
              $counter,
              1,
              {
                y: '100%',
              },
              {
                y: 0,
              },
            );
            TweenMax.fromTo(
              $('.fancy-slider__counter .line .inner'),
              delay / 1000,
              {
                x: '-100%',
              },
              {
                x: '100%',
              },
            );
          },
        },
      },
      scrolls: [],
      cases: [],
      page: 1,
      loading: false,
      pageCount: 2,
      types: null,
      type: 0,
      hasMore: false,
      proloadImages: [],
    };
  },
  computed: {
    noMore() {
      return this.page >= this.pageCount;
    },
    disabled() {
      return this.loading || this.noMore;
    },
    getTypes() {
      return this.$store.state.typesList;
    },
    innerHeight() {
      return window.innerHeight;
    },
  },
  watch: {
    getTypes(val) {
      if (this.type === 0) {
        this.type = val['0'].id;
      }
      const currentType = val.filter((item) => Number(item.id) === Number(this.type));
      this.hasMore = Number(currentType['0'].hasSimple);
    },
    type(val) {
      if (this.getTypes) {
        const currentType = this.getTypes.filter((item) => Number(item.id) === Number(val));
        this.hasMore = Number(currentType['0'].hasSimple);
      }
      // this.getCases();
    },
    $route: {
      handler: 'init',
      immediate: true,
    },
  },
  methods: {
    async init() {
      const that = this;
      that.proloadImages = [];
      const { type } = that.$route.query;
      that.type = type || 1;
      that.$store.commit('openLoading');
      const t1 = new Date().getTime();
      try {
        await that.getScrolls();
        await that.getCases();
      } catch (e) {
        Error(e);
      }
      await that.$nextTick();
      await preloadDom(document.querySelectorAll('.lazyload'));
      const t2 = new Date().getTime();
      if (t2 - t1 <= 500) {
        setTimeout(() => {
          that.$store.commit('closeLoading');
        }, 1000);
      } else {
        that.$store.commit('closeLoading');
      }
    },
    async getScrolls() {
      const that = this;
      let scrolls = [];
      const res = await this.$http.get('/api/scroll/list', {
        type: this.$isMobile ? 2 : 1,
      });
      if (res.status === 0) {
        scrolls = res.data.lists;
        scrolls = scrolls.map((item) => {
          const temp = item;
          const imgs = JSON.parse(item.img);
          temp.img = this.Tool.isPc() ? imgs.pc : imgs.mobile;
          temp.title = this.Tool.nl2br(item.title);
          that.proloadImages.push(temp.img);
          return temp;
        });
      }
      this.scrolls = scrolls;
    },
    async getCases() {
      const that = this;
      that.loading = true;
      that.cases = [];
      that.$store.commit('openLoading');
      try {
        const res = await that.$http.get('/api/product/list', {
          page: 0,
          type: that.type,
          is_on_home: 1,
        });
        const { lists, meta } = res.data;
        lists.map((item) => {
          const temp = item;
          temp.title = JSON.parse(item.name);
          temp.location = JSON.parse(item.location);
          that.proloadImages.push(temp.cover);
          return temp;
        });
        that.cases = lists;
        that.pageCount = meta.pageCount;
        that.page += 1;
      } catch (e) {
        Error('图片错误');
      }
      that.loading = false;
    },
    switchType(type) {
      this.$router.push({
        path: '/index.html',
        query: {
          type,
        },
      });
    },
  },
};
</script>

<style></style>
